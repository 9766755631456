/**
 * User tracking service
 */

export class UserTrackingService {
  // Anonymous user tracking is handle through Tag Manager

  /**
   * Handles identified users (with email)
   */
  static connectIdentifiedUser(email) {
    // Adjust tracking
    this.connectAdjustUserId(email);

    // Braze tracking
    this.connectBrazeUserId(email);
  }

  /**
   * Connect Adjust with user identity
   */
  static connectAdjustUserId(userEmail) {
    if (!userEmail) {
      return;
    }

    try {
      window.Adjust?.trackEvent({
        eventToken: 'z9yfpv',
        callbackParams: [{key: 'user_email', value: userEmail}]
      });
    } catch (error) {
      console.error('Adjust ID connection failed:', error);
    }
  }

  /**
   * Connect Braze with user identity
   */
  static connectBrazeUserId(userEmail) {
    try {
      window.braze?.getUser()?.addAlias('user_email', userEmail);
    } catch (error) {
      console.error('Braze ID connection failed:', error);
    }
  }
}
