import qs from 'qs';
import {extendObservable, action, decorate} from 'mobx';
import {loadBeacon} from 'helpers/events';
import {addDataLayer, openBeacon} from 'helpers/commonHelper';
import {MOBILE_WIDTH, isAirtickets} from 'helpers/stableValues';
import Cookies from 'js-cookie';
import {isProduction} from '../../helpers/stableValues';
import {getParams} from '../../helpers/urlParams';
import {setSession} from '../../helpers/commonHelper';
import {getAuthParams} from 'providers/user/utils';
import {setCustomerUserId} from 'providers/appsflyer';
import {UserTrackingService} from 'utils/userTracking';

function eventTrengoBeacon() {
  if (((/^\/[a-z]{2}\/.+\/(results|seating|payment|confirmation|open-chat)/)
    .test(location.pathname) ||
      (/^\/[a-z]{2}\/(ferry|aktoploika|faehre|traghetti|feribot|farja|faerge|lautta|ferge|laevapiletid)$/)
        .test(location.pathname)) && (window.innerWidth > MOBILE_WIDTH)
  ) {
    addDataLayer({event: loadBeacon});
  }
  const {openChat} = qs.parse(location.search.slice(1));
  if (openChat === '1') {
    setTimeout(() => {
      openBeacon();
    }, 1000);
  }
}

class NavbarStore {
  constructor({
    brand,
    getHost,
    website,
    links,
    locales,
    localeFull,
    brandLocale,
    multicurrency,
    currency,
    localizationChannel,
    employeeChannel,
    contactForm,
    getUserAccount,
    translations
  }) {
    const isPreferredCurrencyEnabled = multicurrency.isMulticurrencyEnabled && !isAirtickets;

    extendObservable(this, {
      employee: null,
      account: null
    });

    Object.assign(this, {
      brand,
      translations,
      website,
      brandLocale,
      contactForm,
      host: getHost(),
      preferredLocale: localeFull,
      preferredCurrency: currency,
      links,
      locales,
      defaultLocale: localeFull,
      currencies: multicurrency.availableCurrencies,
      isPreferredCurrencyEnabled,
      defaultCurrency: currency,
      localizationChannel,
      getUserAccount
    });

    const params = getParams(window.location.href) || {};

    const {tp24id, uuid} = params;
    if (tp24id && uuid) {
      localStorage.setItem('user', JSON.stringify({uuid, session_token: tp24id}));
      Cookies.set(
        'TP24ID',
        tp24id,
        {path: '/', secure: isProduction, sameSite: 'lax'}
      );

      setSession({tp24Id: tp24id});
    }

    const userAccount = getAuthParams();
    if (userAccount) {
      this.fetchAccountDetails();
    }

    if (employeeChannel) {
      employeeChannel.subscribe('update', (employee) => {
        this.employee = employee;
      });
    }

    setCustomerUserId();
    eventTrengoBeacon();
  }

  fetchAccountDetails = async () => {
    try {
      const {body, status} = await this.getUserAccount();
      if (status === 200) {
        this.account = body.response.result.accounts[0];
        UserTrackingService.connectIdentifiedUser(this.account.email);
      } else if (status === 401) {
        localStorage.removeItem('user');
      }
    } catch {
      localStorage.removeItem('user');
    }
  };
}

decorate(NavbarStore, {
  fetchAccountDetails: action
});

export default NavbarStore;
